<script setup lang="ts">
import { defineProps, computed } from "vue"
import { zxcvbn, zxcvbnOptions } from "@zxcvbn-ts/core"
import * as zxcvbnCommonPackage from "@zxcvbn-ts/language-common"
import { VIcon } from "@infobex/vue-ui-lib"

const props = withDefaults(
	defineProps<{
		password: string
		name?: string
		min: 0 | 1 | 2 | 3 | 4
	}>(),
	{
		name: "__password_complexity"
	}
)

zxcvbnOptions.setOptions({
	graphs: zxcvbnCommonPackage.adjacencyGraphs,
	dictionary: {
		...zxcvbnCommonPackage.dictionary
	}
})

const score = computed(() => {
	return zxcvbn(props.password).score
})

const labels = {
	0: "gyenge",
	1: "gyenge",
	2: "gyenge",
	3: "megfelelő",
	4: "jó"
}

defineExpose({
	score
})
</script>
<template>
	<div class="password-meter" :data-score="score">
		<div class="password-meter-label">
			Jelszó erőssége:
			<strong>{{ labels[score] }}</strong>
			<!-- <VIcon :i="icons[score]" size="20" /> -->
		</div>
		<input type="number" :name="name" :value="score" :min="min" readonly hidden />

		<div class="password-meter-track">
			<span class="password-meter-indicator" :data-score="score" />
		</div>
	</div>
</template>
<style lang="scss">
.password-meter {
	@apply flex flex-col gap-1;

	// &-track-wrapper {
	// 	@apply flex items-center;
	// }
	@apply py-2 px-1;

	&-track {
		// @apply rounded;
		// @apply border-2 border-neutral-400;
		@apply bg-neutral-400 bg-opacity-50;

		width: 100%;
		padding: 1px;
		overflow: hidden;
		height: 10px;

		border-radius: 4px;
		// max-width: 256px;

		// box-shadow: inset 0 0 6px 0px rgba(0, 0, 0, 0.2);
	}

	&-label {
		width: 100%;
		line-height: normal;
		@apply pl-2;
		@apply text-sm;
		@apply font-semibold;
		// @apply text-neutral-700;
		@apply flex items-center gap-1;
		// text-align: right;
		input {
			@apply bg-transparent font-mono;
			// padding: 0;
			width: 3ch;
			text-align: center;
		}
	}

	&-indicator {
		display: block;
		border-radius: 3px;
		// border-radius: 20px;
		height: 100%;

		// box-shadow: inset 0 0 0px 1px rgba(0, 0, 0, 0.1);

		@apply transition-all;

		&[data-score="0"] {
			opacity: 0;
			width: 0;
			@apply bg-red-600;
		}
		&[data-score="1"] {
			width: 25%;
			@apply bg-red-600;
		}
		&[data-score="2"] {
			width: 50%;
			@apply bg-red-600;
		}
		&[data-score="3"] {
			width: 75%;
			@apply bg-yellow-600;
		}
		&[data-score="4"] {
			width: 100%;
			@apply bg-green-500;
		}
	}
}
</style>
