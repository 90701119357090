<script setup lang="ts">
/**
 * TODO:
 *  - Make this piece of crap responsive
 */
import dayjs, { Dayjs } from "dayjs"

import { prettyPrintDateRange } from "@/utils/date"
import { VTextInput } from "@infobex/vue-ui-lib/unstable"
import { VIcon, useModal } from "@infobex/vue-ui-lib/core"

import Dialog from "./Dialog.vue"

const props = withDefaults(
	defineProps<{
		minDate: Dayjs
		maxDate: Dayjs
	}>(),
	{
		minDate: () => dayjs().subtract(1, "year"),
		maxDate: () => dayjs()
	}
)

const modelValue = defineModel<[Dayjs, Dayjs]>({ default: () => [dayjs().subtract(1, "days"), dayjs()] })

async function openDialog() {
	const date = await useModal(Dialog, {
		defaultValue: modelValue.value,
		minDate: props.minDate,
		maxDate: props.maxDate
	})

	if (date) modelValue.value = date
}
</script>
<template>
	<VTextInput
		type="text"
		:model-value="prettyPrintDateRange(modelValue[0], modelValue[1]) ?? '--'"
		readonly
		@click="openDialog"
	>
		<template #start>
			<VIcon i="calendar" />
		</template>
	</VTextInput>
</template>
